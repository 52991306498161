import {
    Box,
    Button,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { LinkHTMLAttributes, memo, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import breaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import { SlackMarkdownOptions, toHTML } from 'slack-markdown';
import { firebaseApp } from '../App';
import { ARTICLE_BASE_FONT_SIZE, ArticleTheme } from '../Theme';
import { useColorModeContext } from './Context';
import { FlexBox } from './FlexBox';
import { IconText } from './IconText';
import { ContentCopyIcon, DoneIcon, ImageIcon } from './Icons';
import { ExternalLink } from './RouterLink';

export const Markdown: React.FC<{ source: string; variant?: Variant; sx?: SxProps }> = (props) => {
    return (
        <Typography
            component="div"
            variant={props.variant ?? 'inherit'}
            sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                ...props.sx,
            }}
        >
            <ReactMarkdown
                components={{
                    a: (props: LinkHTMLAttributes<any>) => (
                        <ExternalLink underline="always" href={props.href}>
                            {props.children}
                        </ExternalLink>
                    ),
                }}
                remarkPlugins={[breaks, remarkGfm]}
            >
                {props.source}
            </ReactMarkdown>
        </Typography>
    );
};

/**
 * Fix unintended italic rendering.
 * https://github.com/voyagegroup/carta_chihaya/issues/2399
 */
const fixBrokenName = (data: { id: string; name: string }): string =>
    data.name.replace(/<\/?em>/g, '_');

export const SlackMrkDwn: React.FC<{ source: string }> = (props) => {
    const theme = useTheme();
    const options: SlackMarkdownOptions = {
        hrefTarget: '_blank',
        slackCallbacks: {
            channel: (data) => `#${fixBrokenName(data)}`,
            user: (data) => `@${fixBrokenName(data)}`,
        },
    };
    return (
        <Box
            sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                '& .s-mention': {
                    color: theme.palette.primary.main,
                },
                '& a': {
                    color: theme.palette.link,
                },
            }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: toHTML(props.source, options),
                }}
            />
        </Box>
    );
};

type ImageProps = Omit<
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'ref'
>;

export const ArticleMarkdown: React.FC<{
    source: string;
    fontSize?: number;
}> = memo((props) => {
    const theme = useTheme();
    const { colorMode } = useColorModeContext();
    return (
        <ArticleTheme fontSize={props.fontSize}>
            <Box
                sx={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    'ul, ol': {
                        fontSize: props.fontSize ?? ARTICLE_BASE_FONT_SIZE,
                        fontFamily:
                            "Roboto, 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴシック Pro', sans-serif",
                        lineHeight: 1.6,
                    },
                    'ul ul, ol ol': {
                        marginBottom: '1rem',
                    },
                    li: {
                        margin: '0.25rem 0',
                    },
                }}
            >
                <ReactMarkdown
                    components={{
                        a: (props: LinkHTMLAttributes<any>) => (
                            <ExternalLink underline="always" href={props.href}>
                                {props.children}
                            </ExternalLink>
                        ),
                        h1: (props) => <Typography variant="h1">{props.children}</Typography>,
                        h2: (props) => <Typography variant="h2">{props.children}</Typography>,
                        h3: (props) => <Typography variant="h3">{props.children}</Typography>,
                        h4: (props) => <Typography variant="h4">{props.children}</Typography>,
                        h5: (props) => <Typography variant="h5">{props.children}</Typography>,
                        h6: (props) => <Typography variant="h6">{props.children}</Typography>,
                        p: (props) => <Typography component="div">{props.children}</Typography>,
                        blockquote: ({ children }) => (
                            <Box
                                pl={1}
                                sx={{
                                    borderLeft: `4px solid ${theme.palette.divider}`,
                                }}
                            >
                                {children}
                            </Box>
                        ),
                        img: (props) => {
                            if (props.src === undefined) {
                                return null;
                            }
                            if (!props.src.startsWith('/')) {
                                return <IconText icon={ImageIcon}>{props.alt ?? 'image'}</IconText>;
                            }
                            const { node, ...rest } = props;
                            return <UploadedImage {...rest} />;
                        },
                        pre: (props) => <Box>{props.children}</Box>,
                        code: (props) => {
                            const sx: SxProps = {
                                position: 'relative',
                                py: 0.5,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: theme.palette.divider,
                                backgroundColor: theme.palette.background.paper,
                                color: theme.palette.text.primary,
                                whiteSpace: 'pre',
                                fontFamily: '"Ubuntu Mono", monospace',
                            };
                            if (props.inline) {
                                return (
                                    <Typography
                                        component="span"
                                        display="inline"
                                        mx={0.5}
                                        px={0.5}
                                        sx={sx}
                                    >
                                        {props.children}
                                    </Typography>
                                );
                            }
                            const language =
                                props.className?.split('-')[1].toLowerCase() ?? undefined;
                            const source = props.children[0]?.toString() ?? '';
                            return (
                                <Box my={2} sx={sx}>
                                    <CodeCopyButton source={source} />
                                    <SyntaxHighlighter
                                        language={language}
                                        customStyle={{
                                            padding: '0 4rem 0 1rem',
                                            background: 'transparent',
                                        }}
                                        codeTagProps={{
                                            style: {
                                                fontFamily: '"Ubuntu Mono", monospace',
                                                fontSize: '0.9rem',
                                            },
                                        }}
                                        wrapLongLines={true}
                                        style={colorMode === 'dark' ? atomOneDark : atomOneLight}
                                    >
                                        {props.children.join('')}
                                    </SyntaxHighlighter>
                                </Box>
                            );
                        },
                        table: ({ children }) => <Table>{children}</Table>,
                        thead: ({ children }) => <TableHead>{children}</TableHead>,
                        tbody: ({ children }) => <TableBody>{children}</TableBody>,
                        tr: ({ children }) => <TableRow>{children}</TableRow>,
                        th: ({ children }) => <TableCell>{children}</TableCell>,
                        td: ({ children }) => <TableCell>{children}</TableCell>,
                    }}
                    remarkPlugins={[breaks, remarkGfm]}
                >
                    {props.source}
                </ReactMarkdown>
            </Box>
        </ArticleTheme>
    );
});

const UploadedImage: React.FC<ImageProps> = memo((props) => {
    const { src, alt, ...rest } = props;
    const fixedAlt = alt ?? 'image';
    const [url, setUrl] = useState<string | null>(null);
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
        const uploadRef = ref(getStorage(firebaseApp), src);
        void getDownloadURL(uploadRef).then((url) => {
            setUrl(url);
            const img = new Image();
            img.onload = () => {
                setIsLandscape(img.width > img.height);
            };
            img.src = url;
        });
    }, [src]);

    const sx: SxProps =
        typeof alt === 'string' && /^\d+%?$/.test(alt)
            ? { width: alt }
            : isLandscape
              ? { maxWidth: { xs: '100%', sm: '600px' } }
              : { maxWidth: { xs: '440px', lg: '50%' } };

    return url === null ? (
        <IconText icon={ImageIcon}>{fixedAlt}</IconText>
    ) : (
        <Box sx={sx}>
            <img src={url} alt={fixedAlt} {...rest} style={{ maxWidth: '100%' }} />
        </Box>
    );
});

const CodeCopyButton: React.FC<{ source: string }> = (props) => {
    const [copied, setCopied] = useState(false);
    return (
        <Tooltip
            title={
                copied ? (
                    <FlexBox>
                        <DoneIcon color="secondary" fontSize="small" />
                        コピーしました
                    </FlexBox>
                ) : (
                    'コピーする'
                )
            }
            arrow
            placement="bottom"
            onOpen={() => setCopied(false)}
            onClose={() =>
                // keep the message until the tooltip is faded out
                setTimeout(() => {
                    setCopied(false);
                }, 200)
            }
        >
            <Button
                variant="outlined"
                sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    p: 0.5,
                    px: 1.5,
                    minWidth: 'auto',
                }}
                onClick={() =>
                    navigator.clipboard.writeText(props.source).then(() => setCopied(true))
                }
            >
                <ContentCopyIcon fontSize="small" color="primary" />
            </Button>
        </Tooltip>
    );
};
